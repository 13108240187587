<template>
  <div
    style="max-width: 500px"
    class="border-primary-light radius-6 fill-width mx-auto"
  >
    <v-card-text>
      <!-- head -->
      <v-row align="center">
        <v-col cols="12" class="text-center py-0">
          <v-row class="ma-2">
            <v-col class="px-0" cols="auto">
              <AppImage
                :appId="get(appInfo, 'id', '')"
                :image="get(appInfo, 'image', '')"
                size="40"
                class="d-flex justify-center align-center"
              />
            </v-col>
            <v-col class="text-left px-0" cols="auto">
              <div class="mx-4 d-flex flex-column">
                <span class="black--text bold-text">
                  {{ get(appInfo, "name", "") }}
                </span>
                <vue-hoverable-date :date="get(appInfo, 'created_at', '')" />
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="text-center text-h6" cols="12"> Latest releases </v-col>
        <v-col cols="12">
          <v-row>
            <v-col v-if="!latestReleases.length">
              <inline-svg
                class="mt-6 svg-icon empty_icon"
                src="/media/placeholders/no_devices.svg"
              />

              <p
                class="mt-4 empty-title bodyFont bold-text text-dark text-center"
              >
                No latest releases, just yet...
              </p>
            </v-col>
            <template v-else>
              <v-expansion-panels flat>
                <template v-for="(release, index) in latestReleases">
                  <v-expansion-panel
                    class="transparentBackground lightBorder"
                    :key="index"
                  >
                    <v-expansion-panel-header
                      disable-icon-rotate
                      class="lightBackground"
                    >
                      <v-row>
                        <v-col cols="auto">
                          <v-icon
                            v-text="
                              release.platform === 'android'
                                ? 'mdi-android'
                                : 'mdi-apple'
                            "
                          ></v-icon>
                        </v-col>
                        <v-col cols="auto">
                          <div>
                            {{ "v" + get(release, "info.version", "0") }}
                          </div>
                          <div class="grey-text caption">
                            <vue-hoverable-date
                              :date="release.created_at"
                            ></vue-hoverable-date>
                          </div>
                        </v-col>
                      </v-row>
                      <template #actions>
                        <div>
                          <div>
                            <div v-if="appInfo.public">
                              <install-button
                                is-public
                                icon
                                :release-url="release.url"
                                :platform="release.platform"
                              ></install-button>
                            </div>
                            <div v-else>
                              <install-button
                                icon
                                v-if="
                                  release.platform === 'android' &&
                                  getMobileOperatingSystem === 'android'
                                "
                                :release-url="release.url"
                              ></install-button>
                              <install-button
                                icon
                                v-if="
                                  release.platform === 'ios' &&
                                  getMobileOperatingSystem === 'ios'
                                "
                                :release-url="release.url"
                              ></install-button>
                            </div>
                          </div>
                          <div class="caption grey-text text-center">
                            {{ formatBytes(get(release, "info.size", 0) || 0) }}
                          </div>
                        </div>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="lightBackground">
                      <div class="mb-2">
                        <span class="bold-text mr-3 px-0"> Package: </span>
                        {{ release.info.package }}
                      </div>
                      <div class="mb-2">
                        <span class="bold-text mr-3 px-0"> Version: </span>
                        {{ get(release, "info.version", "0") }} ({{
                          get(release, "info.version_code", "0")
                        }})
                      </div>
                      <div class="mb-2">
                        <span class="bold-text mr-3 px-0"> By: </span>
                        {{ get(release, "user.name", "") }}
                      </div>
                      <div class="mb-2">
                        <span class="bold-text mr-3 px-0"> Note: </span>
                        {{ release.note || "-" }}
                      </div>
                      <div v-if="appInfo.public">
                        <install-button
                          is-public
                          :release-url="release.url"
                          :platform="release.platform"
                        ></install-button>
                      </div>
                      <div v-else>
                        <install-button
                          v-if="
                            release.platform === 'android' &&
                            getMobileOperatingSystem === 'android'
                          "
                          :release-url="release.url"
                        ></install-button>
                        <div
                          class="text-center"
                          v-if="
                            release.platform === 'android' &&
                            getMobileOperatingSystem !== 'android'
                          "
                        >
                          <qrcode :value="release.url" :options="{ width: 200 }" />
                          <div class="mb-2">Scan this QR code from your android device or <a :href="release.url" target="_blank">click here </a> download app</div>
                        </div>
                        <install-button
                          v-if="
                            release.platform === 'ios' &&
                            getMobileOperatingSystem === 'ios'
                          "
                          :release-url="release.url"
                        ></install-button>
                        <div
                          class="text-center"
                          v-if="
                            release.platform === 'ios' &&
                            getMobileOperatingSystem !== 'ios'
                          "
                        >
                          <qrcode :value="release.url" :options="{ width: 200 }" />
                          <div class="mb-2">Scan this QR code from your iOS device or <a :href="release.url" target="_blank">click here </a> download app</div>
                        </div>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </template>
              </v-expansion-panels>
            </template>
          </v-row>
        </v-col>
        <v-col cols="12"></v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import { isEmpty, get, formatBytes } from "@/core/services/helper.service";
import InstallButton from "@/view/components/Common/InstallButton";
import AppImage from "@/view/components/App/Image.vue";

export default {
  components: { InstallButton, AppImage },
  props: {
    info: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isEmpty,
      get,
      formatBytes,
    };
  },
  computed: {
    appInfo() {
      return this.info.app;
    },
    latestReleases() {
      return this.info.latest_releases;
    },
  },
  created() {
    // this.getInfo();
  },
  methods: {
    // getInfo() {
    //   this.$store.dispatch(GET_INSTALL_INFO).then(response => {
    //     this.appInfo = response.data;
    //   });
    // }
  },
};
</script>

<style scoped></style>
