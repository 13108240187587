<template>
  <div class="white fill-height">
    <div class="fill-width align-self-start">
      <v-card
        color="primary"
        flat
        class="d-flex align-center justify-center fill-width"
        height="110"
        tile
      >
        <a href="#" class="text-center pa-5">
          <img
            :src="
              fromExpress
                ? '/media/logos/logo_express.png'
                : '/media/logos/logo_light_155x30.png'
            "
            class="max-h-70px"
            alt
          />
        </a>
      </v-card>
    </div>
    <v-container
      class="fill-width d-flex flex-column align-center justify-center"
      style="height: calc(100% - 110px)"
    >
      <div class="fill-width">
        <content-loader class="my-16" v-if="loading" />
        <div v-if="getOpenSigninProcess">
          <auth-check
            title="You need to be authorized"
            sub-title="Please follow the process"
            @refresh="getInstallInfo"
          ></auth-check>
        </div>
        <!--        Express Install  info start here-->
        <div
          v-if="fromExpress && !isEmpty(expressReleaseInfo)"
          style="max-width: 500px"
          class="border-primary-light radius-6 fill-width mx-auto"
        >
          <v-card-text>
            <div class="d-flex">
              <AppImage
                :appId="get(appReleaseInfo, 'id', '')"
                :image="get(expressReleaseInfo, 'file.icon', '')"
                size="40"
                class="d-flex justify-center align-center"
              />
              <div class="mx-4 d-flex flex-column">
                <span class="black--text bold-text">
                  {{ get(expressReleaseInfo, "file.name", "") }}
                </span>
                <vue-hoverable-date
                  :date="get(expressReleaseInfo, 'created_at', '')"
                />
              </div>
              <v-spacer />

              <span
                class="
                  d-flex
                  flex-column
                  align-center
                  justify-center
                  text-center
                  align-self-center
                "
              >
                <span class="bold-text font-size-h5 mt-n1">
                  {{ formatBytes(get(expressReleaseInfo, "file.size", 0)) }}
                </span>
              </span>
            </div>
            <v-expand-transition>
              <div>
                <v-divider class="mb-3 mt-4" />
                <div>
                  <v-row no-gutters class="mb-1">
                    <v-col cols="3" class="bold-text mr-2">File name:</v-col>
                    <v-col>{{
                      get(expressReleaseInfo, "file.file_name", "")
                    }}</v-col>
                  </v-row>
                  <v-row no-gutters class="mb-1">
                    <v-col cols="3" class="bold-text mr-2">Platform:</v-col>
                    <v-col>{{ get(expressReleaseInfo, "platform", "") }}</v-col>
                  </v-row>
                  <!--  -->
                  <!--  -->
                  <v-row no-gutters class="mb-1">
                    <v-col cols="3" class="bold-text mr-2">Package:</v-col>
                    <v-col>{{
                      get(expressReleaseInfo, "file.package", "")
                    }}</v-col>
                  </v-row>
                  <!--  -->
                  <!--  -->
                  <v-row no-gutters class="mb-1">
                    <v-col cols="3" class="bold-text mr-2">Size:</v-col>
                    <v-col>{{
                      formatBytes(get(expressReleaseInfo, "file.size", 0))
                    }}</v-col>
                  </v-row>
                  <!--  -->
                  <!--  -->
                  <v-row no-gutters class="mb-1">
                    <v-col cols="3" class="bold-text mr-2">Version:</v-col>
                    <v-col>
                      {{ get(expressReleaseInfo, "file.version", "") }}
                      ({{ get(expressReleaseInfo, "file.version_code", "") }})
                    </v-col>
                  </v-row>
                  <v-divider class="mb-3 mt-4" />
                </div>
                <div
                  class="text-center"
                  v-if="expressReleaseInfo.platform !== mobile"
                >
                  <qrcode :value="getUrl" :options="{ width: 200 }" />
                  <div class="mb-2">
                    Scan above QR code from your
                    {{
                      expressReleaseInfo.platform === "android"
                        ? "android"
                        : "iOS"
                    }}
                    device

                    <div v-if="expressReleaseInfo.platform === 'android'">
                      or
                      <a :href="expressReleaseInfo.url" target="_blank"
                        >click here</a
                      >
                      to the download app
                    </div>
                  </div>
                </div>
                <!--                <div-->
                <!--                  class="text-center font-weight-bold"-->
                <!--                  v-if="expressReleaseInfo.platform !== mobile"-->
                <!--                >-->
                <!--                  {{ getInfoMessage(expressReleaseInfo.platform) }}-->
                <!--                </div>-->
                <install-button
                  fill-width
                  v-if="
                    expressReleaseInfo &&
                    expressReleaseInfo.platform === 'ios' &&
                    mobile === 'ios'
                  "
                  :release-id="releaseID"
                  from-express
                  :release-url="expressReleaseInfo.url"
                  :app-id="appID"
                ></install-button>
                <install-button
                  v-if="
                    expressReleaseInfo &&
                    expressReleaseInfo.platform === 'android' &&
                    mobile === 'android'
                  "
                  :release-id="releaseID"
                  from-express
                  :release-url="expressReleaseInfo.url"
                  :app-id="appID"
                ></install-button>
              </div>
            </v-expand-transition>
          </v-card-text>
        </div>
        <!-- Express Install  info end here-->

        <!-- Install information for app with latest releases -->
        <div v-if="!releaseID && !isEmpty(installAppInfo)">
          <install-app-info-card :info="installAppInfo"></install-app-info-card>
        </div>
        <!-- Install information for app with latest releases -->

        <!-- Install information when user share only specific releases -->
        <div
          v-else-if="!isEmpty(appReleaseInfo)"
          style="max-width: 500px"
          class="border-primary-light radius-6 fill-width mx-auto"
        >
          <v-card-text>
            <div class="d-flex">
              <AppImage
                :appId="get(appReleaseInfo, 'id', '')"
                :image="get(appReleaseInfo, 'app.image', '')"
                size="40"
                class="d-flex justify-center align-center"
              />
              <div class="mx-4 d-flex flex-column">
                <span class="black--text bold-text">
                  {{ get(appReleaseInfo, "app.name", "") }}
                </span>
                <vue-hoverable-date
                  :date="get(appReleaseInfo, 'created_at', '')"
                />
              </div>
              <v-spacer />

              <span
                class="
                  d-flex
                  flex-column
                  align-center
                  justify-center
                  text-center
                  align-self-center
                "
              >
                <span class="bold-text font-size-h5 mt-n1">
                  {{ formatBytes(get(appReleaseInfo, "file.size", 0)) }}
                </span>
              </span>
            </div>
            <v-expand-transition>
              <div>
                <v-divider class="mb-3 mt-4" />
                <div>
                  <v-row no-gutters class="mb-1">
                    <v-col cols="3" class="bold-text mr-2">File name:</v-col>
                    <v-col>{{ get(appReleaseInfo, "file.name", "") }}</v-col>
                  </v-row>
                  <!--  -->
                  <!--  -->
                  <v-row no-gutters class="mb-1">
                    <v-col cols="3" class="bold-text mr-2">Package:</v-col>
                    <v-col>{{ get(appReleaseInfo, "file.package", "") }}</v-col>
                  </v-row>
                  <!--  -->
                  <!--  -->
                  <v-row no-gutters class="mb-1">
                    <v-col cols="3" class="bold-text mr-2">Size:</v-col>
                    <v-col>{{
                      formatBytes(get(appReleaseInfo, "file.size", 0))
                    }}</v-col>
                  </v-row>
                  <!--  -->
                  <!--  -->
                  <v-row no-gutters class="mb-1">
                    <v-col cols="3" class="bold-text mr-2">Version:</v-col>
                    <v-col>
                      {{ get(appReleaseInfo, "file.version", "") }}
                      ({{ get(appReleaseInfo, "file.version_code", "") }})
                    </v-col>
                  </v-row>
                  <!--  -->
                  <!--  -->
                  <!-- <span class="bold-text mr-2">id :</span>
                  <span>{{ appReleaseInfo.id }}</span> -->
                  <v-divider class="mb-3 mt-4" />
                  <div class="d-flex align-center black--text">
                    <ProfileImage
                      :image="get(appReleaseInfo, 'member.image', '')"
                      :alt="get(appReleaseInfo, 'member.name', '')"
                    />
                    <span class="bold-text ml-3">
                      By {{ get(appReleaseInfo, "member.name", "") }}
                    </span>
                  </div>
                </div>
                <div
                  class="text-center"
                  v-if="appReleaseInfo.platform !== mobile"
                >
                  <qrcode
                    :value="
                      appReleaseInfo.platform == 'android'
                        ? appReleaseInfo.url
                        : getUrl
                    "
                    :options="{ width: 200 }"
                  />
                  <div class="mb-2">
                    Scan above QR code from your
                    {{
                      appReleaseInfo.platform === "android" ? "android" : "iOS"
                    }}
                    device
                    <div v-if="appReleaseInfo.platform === 'android'">
                      or
                      <a :href="appReleaseInfo.url" target="_blank"
                        >click here
                      </a>
                      to the download app
                    </div>
                  </div>
                </div>
                <!--                <div-->
                <!--                  class="text-center py-2 font-weight-bold"-->
                <!--                  v-if="appReleaseInfo.platform !== mobile"-->
                <!--                >-->
                <!--                  -->
                <!--                  {{ getInfoMessage(appReleaseInfo.platform) }}-->
                <!--                </div>-->
                <!-- we can transform this btn into <a href="..." > if we pass tag="a" :href="..." -->
                <install-button
                  :is-public="appReleaseInfo.app && appReleaseInfo.app.public"
                  :platform="appReleaseInfo.platform"
                  :release-url="appReleaseInfo.url"
                  v-if="
                    appReleaseInfo &&
                    appReleaseInfo.platform === 'ios' &&
                    mobile === 'ios'
                  "
                  :release-id="releaseID"
                  :app-id="appID"
                ></install-button>
                <install-button
                  v-if="
                    appReleaseInfo &&
                    appReleaseInfo.platform === 'android' &&
                    mobile === 'android'
                  "
                  :release-id="releaseID"
                  :platform="appReleaseInfo.platform"
                  :release-url="appReleaseInfo.url"
                  :is-public="appReleaseInfo.app && appReleaseInfo.app.public"
                  :app-id="appID"
                ></install-button>
              </div>
            </v-expand-transition>
          </v-card-text>
        </div>
        <!-- end -->
      </div>
      <div
        class="
          py-6
          flex-grow-1
          d-flex
          flex-column flex-root
          justify-center
          align-center
        "
      >
        <!-- For permission error -->
        <vue-inline-alert
          v-if="showPermissionError && getAuthMessage.message"
          class="my-3"
          :message="getAuthMessage.message"
          :messageType="getAuthMessage.type"
        />
        <div class="my-8">
          <inline-svg
            class="svg-icon"
            src="/media/placeholders/no_devices.svg"
            v-if="permissionMessage"
          />
          <p class="mt-4 empty-title bold-text text-dark text-center">
            {{ permissionMessage }}
          </p>
        </div>
        <!-- For permission error end here -->

        <!-- Show message if user open link from desktop or desktop mode -->
        <span>
          <!--          <div-->
          <!--            class="d-flex justify-center align-center"-->
          <!--          >-->
          <!--            <qrcode :value="getUrl" :options="{ width: 200 }" />-->
          <!--          </div>-->
          <vue-inline-alert
            v-if="errorMessage || message"
            :message="errorMessage || message"
            :messageType="errorMessage ? 'error' : 'info'"
            no-close-btn
          />
          <install-button
            v-if="!errorMessage"
            is-text
            :app-id="appID"
            :release-id="releaseID"
          ></install-button>
        </span>
        <!-- If user login with new account and does not have permission, links for dashboard -->
        <div v-if="showPermissionError">
          <v-row class="text-center mb-15 pb-7">
            <v-col>
              <router-link to="/">
                <v-btn class="primary install">Dashboard</v-btn>
              </router-link>
            </v-col>
            <v-col v-if="mobile != 'ios'">
              <a
                href="intent://#Intent;scheme=testappio://;package=testapp.io;S.browser_fallback_url=https%3A%2F%2Fportal.testapp.io%2Fstart;end"
              >
                <img src="/media/svg/google-play.svg" class="install-img" />
              </a>
            </v-col>
            <v-col v-if="mobile != 'android'">
              <a href="https://apps.apple.com/us/app/testapp-io/id1518972541">
                <img src="/media/svg/app-store.svg" class="install-img" />
              </a>
            </v-col>
          </v-row>
        </div>
        <br />
        <!-- FAQ shown only in actual devices -->
        <v-row v-if="releaseID" justify="center" class="faq">
          <v-col
            v-if="
              !loading &&
              !getOpenSigninProcess &&
              !errorMessage &&
              !permissionMessage &&
              getPlatform
            "
            cols="12"
            lg="5"
            class="questions"
          >
            <h1 class="display-1 text-center">FAQ</h1>
            <br />
            <v-expansion-panels flat>
              <template v-if="getPlatform === 'android'">
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    While installing the app on Android, I get a security
                    warning
                    <template #actions>
                      <v-icon left large class="ml-4">expand_more</v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div>
                      On Android 8 or higher, you get a dialog such as
                      <b
                        >"Chrome, For your security, your phone is not allowed
                        to install unknown apps from this source"</b
                      >. <br /><br />
                      Click the <b>Settings</b> button of that dialog and toggle
                      <b>Allow from this source</b>. <br /><br />

                      Alternatively you can navigate to
                      <b>Phone Settings > Apps & notifications</b>. <br /><br />
                      Find the browser application in the list, tap
                      <b>Advanced</b> then <b>Install unknown apps</b> then
                      toggle <b>Allow from this source</b>. <br /><br />
                      On Android 7 or lower, navigate to the device
                      <b>Settings</b> then into the <b>Security</b> category.
                      Under the <b>Device Administration</b> category, toggle
                      <b>Unknown Sources</b>. Then try to install the
                      application again. Please note that some device
                      manufacturers might customize the settings UI and thus
                      menu entries can have different names or be at a different
                      location.
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    While installing the app, I get a "There Was a Problem
                    Parsing the Package" error
                    <template #actions>
                      <v-icon left large class="ml-4">expand_more</v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div>
                      This error could be caused by one of the following
                      reasons:
                      <br />
                      <br />
                      <ul>
                        <li>File may be downloaded incompletely</li>
                        <li>
                          Application might be not suitable for your hardware or
                          OS version
                        </li>
                        <li>Security settings</li>
                        <li>Corrupted APK file</li>
                        <li>Name of the package is changed after signing</li>
                      </ul>
                      <br />
                      <p>
                        To troubleshoot, try reinstalling the app. If that
                        doesn't work, contact the app developer to help resolve
                        the issue.
                      </p>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </template>
              <template v-if="getPlatform === 'ios'">
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    I get the message “Untrusted Enterprise Developer”
                    <template #actions>
                      <v-icon left large class="ml-4">expand_more</v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div>
                      In order for the app to be available for use, you will
                      need to trust the developer's certificate. <br /><br />
                      From your phone’s home screen, tap
                      <b
                        >Settings > General > Profiles or Profiles & Device
                        Management</b
                      >. <br />Under the <b>Enterprise App</b> heading, you see
                      will see a profile for the developer. Tap the name of the
                      developer profile and then confirm you trust them. You
                      should now be able to launch the app.
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header>
                    While installing the app, I get an “Unable to Download App”
                    error, and the app is not installed
                    <template #actions>
                      <v-icon left large class="ml-4">expand_more</v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div>
                      This error could be caused by many reasons, and you should
                      contact the app developer to help resolve the issue.<br /><br />
                      If you are the app developer, this error could be caused
                      by one of the following reasons: <br />

                      <ul>
                        <li>
                          UDID is not included in the provisioning profile
                        </li>
                        <li>
                          The build was incorrectly signed or has broken
                          entitlements
                        </li>
                        <li>
                          Device or iOS version is incompatible with the build
                        </li>
                        <li>Device storage is full</li>
                        <li>Actual download error</li>
                        <li>
                          App is over 50 MB and downloaded over a cellular
                          connection
                        </li>
                      </ul>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header>
                    After installing the app, It appears to download
                    successfully but I am unable to open it
                    <template #actions>
                      <v-icon left large class="ml-4">expand_more</v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div>
                      It could be because your UDID is missingg from
                      provisioning profile. <br />

                      Go to <a href="/udid">install UDID page</a> in safari and
                      continue from there
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header>
                    When I press the “Install” button, the installation alert
                    never shows up and the app never installs on my device
                    <template #actions>
                      <v-icon left large class="ml-4">expand_more</v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div>
                      If you have previously installed the app from the App
                      Store, and it carries the same version of the app that is
                      being installed, there will be no alert to confirm the
                      installation.<br /><br />
                      As a workaround, please try uninstalling the existing
                      version of the app, and then tap the Install button again.
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </template>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </div>
      <vue-snackbar></vue-snackbar>
    </v-container>
  </div>
</template>

<script>
import {
  GET_INSTALL_APP_INFO,
  GET_INSTALL_RELEASE_INFO,
  GET_EXPRESS_RELEASE_INFO,
} from "@/store/releases/install.module.js";
import InstallButton from "@/view/components/Common/InstallButton";
import { formatBytes } from "@/core/services/helper.service";
import AppImage from "@/view/components/App/Image.vue";
import { isEmpty, get } from "@/core/services/helper.service";
import { mapGetters, mapMutations } from "vuex";
import VueSnackbar from "@/view/components/Common/VueSnackbar";
import InstallAppInfoCard from "@/view/components/App/InstallAppInfoCard";
import AuthCheck from "@/view/components/Common/AuthCheck";
export default {
  name: "install",
  props: {
    fromExpress: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AuthCheck,
    InstallAppInfoCard,
    VueSnackbar,
    AppImage,
    InstallButton,
  },
  metaInfo: {
    title: "Install | TestApp.io - Portal Sign In",
    meta: [
      {
        name: "robots",
        content: "noindex,nofollow",
      },
    ],
  },
  data() {
    return {
      isEmpty,
      get,
      appID: "",
      formatBytes,
      openSigninProcess: false,
      releaseID: "",
      mobile: "",
      message: "",
      expressReleaseInfo: {},
      errorMessage: "",
      installAppInfo: {},
      loading: false,
      appReleaseInfo: {},
      showPermissionError: false,
      permissionMessage: "",
    };
  },
  computed: {
    ...mapGetters({
      getOpenSigninProcess: "getOpenSigninProcess",
      getAuthMessage: "getAuthMessage",
    }),
    getUrl() {
      return location.origin + this.$route.path;
    },
    getPlatform() {
      if (this.fromExpress) {
        return this.expressReleaseInfo.platform;
      } else {
        return this.releaseID
          ? this.appReleaseInfo.platform
          : this.getMobileOperatingSystem;
      }
    },
  },
  created() {
    if (this.$route.params) {
      this.appID = this.$route.params.appID;
      this.releaseID = this.$route.params.releaseID;
    }
    this.mobile = this.getMobileOperatingSystem;
    // if (this.mobile === "unknown") {
    //   this.message =
    //     "You will need to open this from your Android or iOS device";
    // } else if (this.mobile === "ios-desktop-mode") {
    //   this.message =
    //     "It seems either you are not on iPhone or iPad or you have the 'Desktop mode' enabled which you will need to change it to 'Mobile mode' first";
    // }
  },
  mounted() {
    if (this.fromExpress) {
      this.getExpressReleaseInfo();
    }
    this.getInstallInfo();
  },
  methods: {
    ...mapMutations({
      setOpenSigninProcess: "setOpenSigninProcess",
    }),
    getInstallInfo() {
      this.setOpenSigninProcess(false);
      // if (this.mobile !== "unknown") {
      if (!this.fromExpress) {
        if (this.$route.params && this.$route.params.releaseID) {
          this.getAppReleaseInfo();
        } else {
          this.getAppInfo();
        }
        // }
      }
    },
    getAppReleaseInfo() {
      this.showPermissionError = false;
      this.permissionMessage = "";
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(GET_INSTALL_RELEASE_INFO, {
          id: this.releaseID,
        })
        .then((response) => {
          this.loading = false;
          this.appReleaseInfo = response.data;
        })
        .catch((err) => {
          this.loading = false;
          if (!this.getOpenSigninProcess && err.status !== 403) {
            this.errorMessage = err.message;
          }
          if (err.status === 403) {
            this.showPermissionError = true;
            this.permissionMessage = err.message;
          }
        });
    },
    getAppInfo() {
      this.showPermissionError = false;
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(GET_INSTALL_APP_INFO, { appId: this.appID })
        .then((response) => {
          this.installAppInfo = response.data;
          this.loading = false;
        })
        .catch((err) => {
          if (!this.getOpenSigninProcess && err.status !== 403) {
            this.errorMessage = err.message;
          }
          if (err.status === 403) {
            this.permissionMessage = err.message;
            this.showPermissionError = true;
          }
          this.loading = false;
        });
    },
    getExpressReleaseInfo() {
      this.errorMessage = null;
      this.$store
        .dispatch(GET_EXPRESS_RELEASE_INFO, this.releaseID)
        .then((response) => {
          this.expressReleaseInfo = response;
          // if (this.mobile === "unknown") {
          //   this.message = `You will need to open this from your ${response.platform} device`;
          // }
        })
        .catch((err) => {
          this.errorMessage = err.message;
        });
    },
    getInfoMessage(device) {
      return `You need to open this from your ${device} device`;
    },
  },
};
</script>

<style lang="scss">
.non-shadow-expansion-panel {
  .v-expansion-panel::before {
    box-shadow: none !important;
  }
}
.faq
  .questions
  .v-expansion-panels
  .v-expansion-panel
  .v-expansion-panel-header {
  font-size: 18px;
  color: #222;
  font-size: 15px;
  padding: 15px 15px;
  line-height: 1.4;
}
.v-btn.install {
  width: 195px !important;
  height: 64px !important;
  border-radius: 10px !important;
  span {
    font-size: 19px !important;
    text-transform: capitalize;
    letter-spacing: 0;
  }
}
img.install-img {
  width: 195px !important;
  height: 64px !important;
}
</style>
